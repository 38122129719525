import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/drupalcon-singapore-compass",
  "date": "5th Feburary 2025",
  "title": "Deep Telemetry with eBPF and Rust",
  "summary": "This talk outlines the journey we have undertaken to build an open-source telemetry system on top of eBPF, Rust, and Go for instrumenting the PHP applications.",
  "author": "Nick Schuch",
  "tag": "Presentation",
  "tagColor": "green",
  "tags": [{
    "name": "opentelemetry"
  }, {
    "name": "hosting"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`Performance profiling is the process of reviewing and debugging applications for bottlenecks and inefficiencies to identify areas for improvement.`}</p>
    <p>{`To profile, we utilise tools that provide us with deep telemetry data to point to these areas of improvement. The golden standard for these tools is paid subscriptions e.g. New Relic and Backfire.io. On the open source side, popular tools such as Xdebug and SPX are great but lack the features needed for profiling in production.`}</p>
    <p>{`Open source options are outclassed by paid subscription-based services because of the effort and deep technical knowledge required to implement them. But there is hope...`}</p>
    <p>{`eBPF (Extended Berkeley Packet Filter) lets programmers load and execute lightweight programs within the Linux kernel. This is a big deal because it enables us to easily observe and instrument our applications in ways that were incredibly difficult to do in the past.`}</p>
    <p>{`This talk outlines the journey we have undertaken to build an open-source telemetry system on top of eBPF, Rust, and Go for instrumenting the PHP applications which run on our hosting platform, Skpr.`}</p>
    <p>{`In this video I cover the following topics:`}</p>
    <ul>
      <li parentName="ul">{`The current state of monitoring and tracing`}</li>
      <li parentName="ul">{`What is eBPF?`}</li>
      <li parentName="ul">{`How to capture tracing data with eBPF`}</li>
      <li parentName="ul">{`What is PHP's new Observer API and why didn't we use it?`}</li>
      <li parentName="ul">{`How to write a PECL extension with Rust`}</li>
      <li parentName="ul">{`The decisions we made when designing our tracing implementation`}</li>
    </ul>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="z9XDYm5UmpI" opts={{
      height: '420',
      width: '100%'
    }} mdxType="YouTube" />
    <h2>{`What's next`}</h2>
    <p>{`We're thrilled to bring this functionality to the Skpr hosting platform, empowering developers to diagnose and debug application performance issues with ease.`}</p>
    <p>{`Stay tuned for updates and exciting new feature announcements!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      